import React, { useState } from "react";
import {
    Typography,
    Box
} from "@mui/material";


export default function NavItem({ item, activeTab, onClick, currentPath }) {
   //const isActive = currentPath === "/" + item.link 

    const isActive = () => {
        if(item.link === "/" && currentPath === "/"){
            return true
        }

        return currentPath.startsWith("/" + item.link )
    }
    

    return (
        <Box
            onClick={onClick}
            fontFamily="Sora"
            sx={{
                paddingBottom: "8px",
                borderBottom: "2px solid",
                borderColor: isActive() ? "#7D5DF4" : "transparent",
                fontFamily: "Sora",
                "&:hover": {
                    borderColor: "#7D5DF4",
                    cursor: "pointer"
                }
            }}>
            <Typography
                fontFamily="Sora"
                fontSize="clamp(1rem, 1.2rem, 2rem)"
            >
                {item.title}
            </Typography>
        </Box>
    );
}
