import React from "react";
import {
    Box,
    Typography,
    useMediaQuery,
} from "@mui/material";
import Divider from "../../shared/components/Divider";
import ContactForm from './ContactForm';
import { Phone, LocationCity, Email } from "@mui/icons-material";
import CircleButton from "../../shared/components/CircleButton";
import SocialLinks from "../../shared/components/SocialLinks";

export default function Contact() {
    const isMobile = useMediaQuery("(max-width:960px)");
    return (
        <Box style={{ backgroundColor: "#F4F9FA" }}>
            <Box
                padding={!isMobile ? "5rem 12rem" : "3rem 1rem"}
                style={{ backgroundColor: "#ECF5F7" }}
            >
                <Box
                    style={{ backgroundColor: "#ECF5F7" }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                >
                    <Box
                        width={isMobile ? "100%" : "50%"}
                        marginBottom="3rem"
                        textAlign="center"
                    >
                        <Typography
                            variant={isMobile ? "h4" : "h2"}
                            fontWeight="bold"
                            gutterBottom
                            fontFamily="Sora"
                            color="#231558"
                        >
                            Contact us
                        </Typography>
                        <Typography
                            fontSize="16px"
                            fontFamily="Sora"
                            sx={{ color: "#77758C" }}
                        >
                            Whether you're seeking to transform your business, find your dream job, or simply have a question, our team is ready to help. Reach out to us and take the first step towards innovation and collaboration.
                        </Typography>
                    </Box>
                </Box>
                <Box marginTop="-2.5rem">
                    <Divider />
                </Box>

                <Box
                    display={"flex"}
                    marginTop={"-1.5rem"}
                    gap={"4rem"}
                    flexDirection={isMobile ? "column" : "row"}
                    justifyContent={"center"}
                >
                    <Box display={"flex"} gap={"1rem"}>
                        <CircleButton
                            size="large"
                            color="#7D5DF4"
                            // onClick={() => window.open(social.link, '_blank')}
                            child={<Phone sx={{ color: "#FFF", fontSize: 26 }} />}
                        />
                        <Box>
                            <Typography
                                fontSize={"18px"}
                                fontWeight="bold"
                                gutterBottom
                                fontFamily="Sora"
                                color="#231558"
                            >
                                Call us
                            </Typography>
                            <Typography
                                fontSize="16px"
                                fontFamily="Sora"
                                sx={{ color: "#77758C" }}
                            >
                                +234 909 7533 650
                            </Typography>
                        </Box>
                    </Box>

                    <Box display={"flex"} gap={"1rem"}>
                        <CircleButton
                            size="large"
                            color="#FF7E4C"
                            // onClick={() => window.open(social.link, '_blank')}
                            child={<Email sx={{ color: "#FFF", fontSize: 26 }} />}
                        />
                        <Box>
                            <Typography
                                fontSize={"18px"}
                                fontWeight="bold"
                                gutterBottom
                                fontFamily="Sora"
                                color="#231558"
                            >
                                Send us an email
                            </Typography>
                            <Typography
                                fontSize="16px"
                                fontFamily="Sora"
                                sx={{ color: "#77758C" }}
                            >
                                info@hafamandco.tech
                            </Typography>
                        </Box>
                    </Box>

                    <Box display={"flex"} gap={"1rem"}>
                        <CircleButton
                            size="large"
                            color="#29C995"
                            // onClick={() => window.open(social.link, '_blank')}
                            child={<LocationCity sx={{ color: "#FFF", fontSize: 26 }} />}
                        />
                        <Box>
                            <Typography
                                fontSize={"18px"}
                                fontWeight="bold"
                                gutterBottom
                                fontFamily="Sora"
                                color="#231558"
                            >
                                Visit our office
                            </Typography>
                            <Typography
                                fontSize="16px"
                                fontFamily="Sora"
                                sx={{ color: "#77758C" }}
                                maxWidth={"70%"}
                            >
                                 6 sama road Sokoto, Nigeria.
                            </Typography>
                        </Box>
                    </Box>
                </Box>

                <Box
                    padding={isMobile ? "1rem" : "6rem 5rem"}
                    marginTop="5rem"
                    style={{ backgroundColor: "#FFF" }}
                    paddingBottom="5rem"
                    sx={{
                        borderRadius: "20px"
                    }}
                    display="flex"
                    flexDirection={isMobile ? "column" : "row"}
                >
                    <Box
                        width={isMobile ? "100%" : "60%"}
                        display="flex"
                        paddingRight="3rem"
                        flexDirection="column"
                    >
                        <Typography
                            fontSize="22px"
                            gutterBottom
                            fontFamily="Sora"
                            color="#231558"
                            fontWeight="bold"
                        >
                            Write us
                        </Typography>
                        <Typography
                            fontFamily="Sora"
                            fontSize="16px"
                            marginTop="1rem"
                            color="#9795A6"
                        >
                            Your thoughts and inquiries are important to us. Drop us a message with your questions, ideas, or feedback, and we'll ensure a member of our team gets back to you promptly.
                        </Typography>
                        <Box display={"flex"} gap={"1rem"} marginTop={"1rem"}>
                            <SocialLinks />
                        </Box>

                        <Box
                            maxWidth={"100%"}
                            height={"auto"}
                            bgcolor={"#FFF"}
                            marginTop={"1.5rem"}
                            border={isMobile ? "4px solid" : "8px solid"}
                            style={{ borderRadius: isMobile ? "12px" : "18px" }}
                        >
                            <img
                                src="https://templatekit.tokomoo.com/recruitmentkit/wp-content/uploads/sites/115/2022/12/image-contact.jpg"
                                width={"100%"}
                                height={"100%"}
                                style={{ borderRadius: "10px" }}
                            />
                        </Box>
                    </Box>

                    <Box
                        width={isMobile ? "100%" : "60%"}
                        display="flex"
                        paddingRight="3rem"
                        flexDirection="column"
                    >
                       <ContactForm   isMobile={isMobile}/>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
