import React from 'react'
import { Box, Typography, useMediaQuery, Link } from "@mui/material";
import Divider from '../../shared/components/Divider';
import SocialLinks from '../../shared/components/SocialLinks';
import { useNavigate } from 'react-router-dom';

export default function Footer() {
    const isMobile = useMediaQuery("(max-width:960px)");
    const currentYear = new Date().getFullYear();
    const navigate = useNavigate()
    return (
        <Box >
            <Box
                padding={!isMobile ? "5rem 12rem" : "3rem"}
                style={{ backgroundColor: "#F4F9FA" }}
                paddingBottom="5rem"
            >
                <Box
                    display="flex"
                    gap="2.5rem"
                    flexDirection={isMobile ? 'column' : 'row'}
                    justifyContent="space-between">
                    <Box>
                        <Typography
                            variant={isMobile ? "h6" : "h4"}
                            gutterBottom
                            fontWeight='bold'
                            fontFamily="Sora"
                        >
                            Hafam & Co
                        </Typography>
                        <Typography
                            fontFamily="Sora"
                            fontSize="16px"
                            color="#7A788E"
                            style={{
                                flexGrow: 1
                            }}
                        >
                           Empowering Business Success Through Innovation and the Right Talent.
                        </Typography>
                    </Box>
                    <Box display='flex' flexDirection={isMobile ? 'column' : 'row'} gap="2rem">
                        <Box>
                            <Typography
                                fontSize="18px"
                                gutterBottom
                                fontWeight='bold'
                                fontFamily="Sora"
                            >
                                Navigation
                            </Typography>
                            <Box display="flex" flexDirection='column' marginTop="1.5rem" gap="0.5rem">
                                <Typography
                                    fontFamily="Sora"
                                    fontSize="16px"
                                    color="#7A788E"
                                    style={{
                                        flexGrow: 1
                                    }}
                                    onClick={() => {
                                        navigate("/")
                                        window.scrollTo({
                                            top: 0,
                                            left: 0,
                                            behavior: "smooth",
                                          });
                                    }}
                                    sx={{
                                        "&:hover": {
                                            cursor: "pointer"
                                        }
                                    }}
                                >
                                    Home
                                </Typography>
                                <Typography
                                    fontFamily="Sora"
                                    fontSize="16px"
                                    color="#7A788E"
                                    style={{
                                        flexGrow: 1
                                    }}
                                    onClick={() => {
                                        navigate("jobs")
                                        window.scrollTo({
                                            top: 0,
                                            left: 0,
                                            behavior: "smooth",
                                          });
                                    }}
                                    sx={{
                                        "&:hover": {
                                            cursor: "pointer"
                                        }
                                    }}
                                >
                                    Job Listing
                                </Typography>
                                <Typography
                                    fontFamily="Sora"
                                    fontSize="16px"
                                    color="#7A788E"
                                    style={{
                                        flexGrow: 1
                                    }}
                                    onClick={() => {
                                        navigate("blog")
                                        window.scrollTo({
                                            top: 0,
                                            left: 0,
                                            behavior: "smooth",
                                          });
                                    }}
                                    sx={{
                                        "&:hover": {
                                            cursor: "pointer"
                                        }
                                    }}
                                >
                                    Articles
                                </Typography>
                            </Box>
                        </Box>
                        <Box>
                            <Typography
                                fontSize="18px"
                                gutterBottom
                                fontWeight='bold'
                                fontFamily="Sora"
                            >
                                Company
                            </Typography>
                            <Box display="flex" flexDirection='column' marginTop="1.5rem" gap="0.5rem">
                                <Typography
                                    fontFamily="Sora"
                                    fontSize="16px"
                                    color="#7A788E"
                                    style={{
                                        flexGrow: 1
                                    }}
                                    onClick={() => {
                                        navigate("about")
                                        window.scrollTo({
                                            top: 0,
                                            left: 0,
                                            behavior: "smooth",
                                          });
                                    }}
                                    sx={{
                                        "&:hover": {
                                            cursor: "pointer"
                                        }
                                    }}
                                >
                                    About Us
                                </Typography>
                                <Typography
                                    fontFamily="Sora"
                                    fontSize="16px"
                                    color="#7A788E"
                                    style={{
                                        flexGrow: 1
                                    }}
                                    onClick={() => {
                                        navigate("about")
                                        window.scrollTo({
                                            top: 1900,
                                            left: 0,
                                            behavior: "smooth",
                                          });
                                    }}
                                    sx={{
                                        "&:hover": {
                                            cursor: "pointer"
                                        }
                                    }}
                                >
                                    Our Team
                                </Typography>
                                <Typography
                                    fontFamily="Sora"
                                    fontSize="16px"
                                    color="#7A788E"
                                    style={{
                                        flexGrow: 1
                                    }}
                                    onClick={() => {
                                        navigate("contact")
                                        window.scrollTo({
                                            top: 0,
                                            left: 0,
                                            behavior: "smooth",
                                          });
                                    }}
                                    sx={{
                                        "&:hover": {
                                            cursor: "pointer"
                                        }
                                    }}
                                >
                                    Contact
                                </Typography>
                            </Box>
                        </Box>
                        <Box>
                            <Typography
                                fontSize="18px"
                                gutterBottom
                                fontWeight='bold'
                                fontFamily="Sora"
                            >
                                Contact
                            </Typography>
                            <Box display="flex" flexDirection='column' marginTop="1.5rem" gap="0.5rem">
                                <Typography
                                    fontFamily="Sora"
                                    fontSize="16px"
                                    color="#7A788E"
                                    style={{
                                        flexGrow: 1
                                    }}
                                >
                                    +234 909 7533 650
                                </Typography>
                                <Typography
                                    fontFamily="Sora"
                                    fontSize="16px"
                                    color="#7A788E"
                                    style={{
                                        flexGrow: 1
                                    }}
                                >
                                    info@hafamandco.tech
                                </Typography>

                            </Box>
                        </Box>
                        <Box>
                            <Typography
                                fontSize="18px"
                                gutterBottom
                                fontWeight='bold'
                                fontFamily="Sora"
                            >
                                Offices
                            </Typography>
                            <Box marginTop="1rem">
                                <Typography
                                    fontFamily="Sora"
                                    fontSize="16px"
                                    color="#7A788E"
                                    style={{
                                        flexGrow: 1
                                    }}
                                >
                                    6 sama road Sokoto, Nigeria.
                                </Typography>
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Divider />
                <Box display="flex" gap="1.5rem">
                    <SocialLinks />
                </Box>
            </Box>
            <Box
                sx={{
                    width: '100%',
                    height: '5rem',
                    backgroundColor: '#7860F5',
                    color: 'white',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'center',
                    padding: '1rem',
                }}
            >
                <Typography variant="body1">
                    Copyright © Hafam&Co {currentYear}
                </Typography>
                <Typography variant="body1">
                    Designed by{" "}
                    <Link
                        href="https://www.softsavvy.ng"
                        target="_blank"
                        rel="noopener noreferrer"
                        color="inherit"
                        underline="hover"
                    >
                        SoftSavvy
                    </Link>
                </Typography>
            </Box>
        </Box>
    )
}
