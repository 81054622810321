import React, { useEffect, useState } from "react";
import {
    Box,
    Typography,
    Button,
    TextField
} from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import CircularProgress from "@mui/material/CircularProgress";

import { useVacancies } from "../contexts/vacancy/useVacancies";


export default function ApplyForm({ isMobile, jobID }) {
    const { applyJob } = useVacancies()
    const [isAgreed, setIsAgreed] = React.useState(false);

    const [firstName, setFirstName] = React.useState("");
    const [lastName, setLastName] = React.useState("");
    const [phoneNumber, setPhoneNumber] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [info, setInfo] = React.useState("");
    const [resume, setResume] = React.useState(null);
    const [isLoading, setIsLoading] = React.useState(false);


    const clearAll = () => {
        setFirstName("")
        setLastName("")
        setPhoneNumber("")
        setPhoneNumber("")
        setEmail("")
        setInfo("")
        setResume("")
    }

    const onFileChanged = (event) => {
        const file = event.target.files[0];
        if (file) {
            setResume(file);
        }
    };


    const handleApply = async (event) => {
        event.preventDefault();

        if (isAgreed && resume) {
            setIsLoading(true)
            await applyJob(firstName, lastName, phoneNumber, email, info, resume, jobID)
            setIsLoading(false)
            clearAll()
            alert("Application sent successfully")
        } else if(!isAgreed) {
            alert("You have to agree to our Terams & Conditions")
        } else if(!resume) {
            alert("Please upload a resume")
        }

    }
    return (
        <>
        <form
            style={{
                width: "100%"
            }}
            onSubmit={handleApply}
        >
            <Box
                display="flex"
                gap={isMobile ? "0rem" : "1.5rem"}
                flexDirection={isMobile ? "column" : "row"}
            >
                <TextField
                    fullWidth
                    required
                    label="First Name"
                    type="text"
                    margin="normal"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                />
                <TextField
                    fullWidth
                    required
                    label="Last Name"
                    type="text"
                    margin="normal"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                />
            </Box>
            <Box
                display="flex"
                gap={isMobile ? "0rem" : "1.5rem"}
                flexDirection={isMobile ? "column" : "row"}
            >
                <TextField
                    fullWidth
                    required
                    label="Phone Number"
                    placeholder="(+123) 4567-890"
                    type="text"
                    margin="normal"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                />
                <TextField
                    fullWidth
                    required
                    label="Email"
                    type="email"
                    margin="normal"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                />
            </Box>

            <TextField
                fullWidth
                label="Additional Information"
                type="text"
                margin="normal"
                placeholder="Add Cover Letter or anything else you want to share"
                multiline
                rows={6}
                value={info}
                onChange={(e) => setInfo(e.target.value)}
            />
            <Button
                variant="contained"
                component="label"
                style={{
                    backgroundColor: "#7D5DF4",
                    padding: "5px 22px",
                    borderRadius: "6px",
                    margin: "1.5rem 0rem",
                    textTransform: "none"
                }}
            >
                Upload Resume
                <input type="file" hidden onChange={onFileChanged}
                    accept=".doc,.txt,.pdf" />
            </Button>
            <Box display="flex" marginLeft="-0.7rem">
                <Checkbox
                    checked={isAgreed}
                    onChange={(event) => setIsAgreed(event.target.checked)}
                    inputProps={{
                        "aria-label": "Checkbox A"
                    }}
                />
                <Typography fontFamily="Sora" fontSize="16px" marginTop="0.6rem">
                    I have read and agree to the Terms & Conditions
                </Typography>
            </Box>
            <Button
                type="submit"
                className="card--button"
                style={{
                    backgroundColor: "#7D5DF4",
                    padding: "5px 22px",
                    borderRadius: "12px",
                    marginTop: "1.5rem",
                    textTransform: "none"
                }}
            >
                <Typography
                    fontSize={isMobile ? "12px" : "20px"}
                    fontFamily="Sora"
                    whiteSpace="nowrap"
                    color="#fff"
                >
                    Submit Application
                </Typography>
            </Button>
        </form>
        {isLoading && (
            <Box
                sx={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    backgroundColor: "rgba(255, 255, 255, 0.8)"
                }}
            >
                <CircularProgress />
            </Box>
        )}

        </>
    );
}
