import React from 'react'
import { Box, Typography, useMediaQuery, Grid, Paper } from "@mui/material";
import Vacancies from '../../../shared/list/Vacancies';
import Locations from '../../../shared/list/Locations';
import { useNavigate } from 'react-router-dom';

export default function Featured() {
    const isMobile = useMediaQuery("(max-width:960px)");
    const maxNum = isMobile ? 4 : 8
    const navigate = useNavigate()

    return (
        <Box
            padding={!isMobile ? "5rem 12rem" : "3rem"}
            style={{ backgroundColor: "#231558" }}
            paddingBottom="5rem"
        >
            <Box>
                <Typography
                    variant={isMobile ? "h4" : "h2"}
                    gutterBottom
                    fontFamily="Sora"
                    color="#FFF"
                >
                    Featured jobs
                </Typography>
                <Box display="flex" flexDirection={isMobile ? "column" : "row"} justifyContent={"space-between"}>
                    <Typography
                        fontFamily="Sora"
                        fontSize="16px"
                        color="#7A788E"
                        style={{
                            flexWrap: 'wrap',
                            
                        }}
                    >
                        Dive into a world of opportunity with Hafam & Co. Our featured jobs spotlight the most exciting roles in tech, innovation, and business development.
                    </Typography>
                    <Typography
                        marginTop={isMobile ? "1.5rem" : "-1rem"}
                        fontFamily="Sora"
                        fontSize="20px"
                        color="#FFF"
                        style={{
                            flexWrap: 'nowrap',
                            marginLeft: 'auto'
                        }}
                        onClick={() => {
                            navigate("jobs")
                            window.scrollTo({
                                top: 0,
                                left: 0,
                                behavior: "smooth",
                              });
                           
                        }}
                        sx={{
                            "&:hover": {
                                color: "#7D5DF4",
                                cursor: "pointer",
                            }
                        }}
                    >
                        View All Listings
                    </Typography>
                </Box>
            </Box>
            <Vacancies maxNum={maxNum} />
            <Locations />
        </Box>
    )
}
