import React from "react";
import { useBlog } from "../../shared/contexts/blog/useBlog";
import { Box, Typography, useMediaQuery, Grid, Paper } from "@mui/material";
import { Blogs } from "../../shared/list/Blogs";
import Divider from "../../shared/components/Divider";
import BlogCard from "../../shared/cards/BlogCard";

export default function Blog() {
    const isMobile = useMediaQuery("(max-width:960px)");
    const { articles } = useBlog();
    return (
        <Box style={{ backgroundColor: "#F4F9FA" }}>
            <Box
                padding={!isMobile ? "5rem 12rem" : "3rem 1rem"}
                style={{ backgroundColor: "#ECF5F7" }}
            >
                <Box
                    style={{ backgroundColor: "#ECF5F7" }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                >
                    <Box
                        width={isMobile ? "100%" : "50%"}
                        marginBottom="3rem"
                        textAlign="center"
                    >
                        <Typography
                            variant={isMobile ? "h4" : "h2"}
                            fontWeight="bold"
                            gutterBottom
                            fontFamily="Sora"
                            color="#231558"
                        >
                            Articles
                        </Typography>
                        <Typography
                            fontSize="16px"
                            fontFamily="Sora"
                            sx={{ color: "#77758C" }}
                        >
                           Our Articles section is a treasure trove of insights and fresh perspectives from the forefront of the tech industry. Dive into discussions on emerging technologies, deep dives into software development practices, and expert opinions on the future of digital business. Stay informed, stay ahead, and let our articles spark new ideas for your next big venture.
                        </Typography>
                    </Box>
                </Box>
                {/* <Box
                    display={"flex"}
                    flexDirection={isMobile ? "column" : "row"}
                    gap={"3.5rem"}
                >
                    <Box width={isMobile ? "100%" : "65%"}>
                    <BlogCard index={0} blog={articles[0]} />
                    </Box>

                    <Box
                        display={"flex"}
                        flexDirection={"column"}
                        width={isMobile ? "100%" : "25%"}
                        marginLeft={"auto"}
                        gap={"1.5rem"}
                    >
                        <BlogCard index={1} blog={articles[1]} />
                        <BlogCard index={2} blog={articles[2]} />
                    </Box>
                </Box> */}
                <Divider />
                <Box
                    marginTop={"3rem"}
                    display={"flex"}
                    flexDirection={"column"}
                    alignItems={"center"}
                    justifyContent={"center"}
                >
                    <Typography
                        alignSelf={"center"}
                        variant={isMobile ? "h6" : "h4"}
                        gutterBottom
                        fontFamily="Sora"
                        color="#231558"
                    >
                        Discover the latest in...
                    </Typography>
                    {/* <Blogs /> */}
                </Box>
            </Box>
        </Box>
    );
}
