const bybit = require('../images/bybit.png');
const kucoin = require('../images/kucoin.png');
const next = require('../images/next.png');
const neom = require('../images/neom.png');
const softsavvy = require('../images/softsavvy.png');


export default {
    bybit,
    kucoin,
    next,
    neom,
    softsavvy
}