import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useVacancies } from "../../shared/contexts/vacancy/useVacancies";
import Divider from "../../shared/components/Divider";
import SocialLinks from "../../shared/components/SocialLinks";
import ApplyForm from "./../../shared/components/ApplyForm";

export default function JobInfo() {
    const { vacancies } = useVacancies();
    const isMobile = useMediaQuery("(max-width:960px)");
    const { jobID } = useParams();

    const vacancy = vacancies.find((vacancy) => vacancy.id === jobID);

    return (
        <Box>
            <Box
                padding={!isMobile ? "7rem 14rem" : "1rem"}
                style={{ backgroundColor: "#231558" }}
                paddingBottom={!isMobile ? "10rem" : "5rem"}
            >
                <Box
                    display="flex"
                    flexDirection={isMobile ? "column" : "row"}
                    gap="1.5rem"
                >
                    <img
                        src={vacancy?.img}
                        alt={vacancy?.title}
                        style={{
                            width: "120px",
                            height: "auto",
                            borderRadius: "12px",
                            marginBottom: "0.5rem"
                        }}
                    />
                    <Box
                        display="flex"
                        flexDirection="column"
                        justifyContent="space-between"
                    >
                        <Typography
                            fontSize="30px"
                            fontWeight="bold"
                            gutterBottom
                            fontFamily="Sora"
                            color="#FFF"
                        >
                            {vacancy?.title}
                        </Typography>
                        <Typography
                            fontFamily="Sora"
                            fontSize="20px"
                            color="#FF7E4C"
                            style={{
                                flexGrow: 1
                            }}
                        >
                            {vacancy?.type}
                        </Typography>
                        <Typography
                            fontFamily="Sora"
                            fontSize="16px"
                            color="#7A788E"
                            style={{
                                flexGrow: 1
                            }}
                        >
                            {vacancy?.location}
                        </Typography>
                    </Box>
                </Box>
            </Box>
            <Box
                padding={!isMobile ? "7rem 14rem" : "1rem"}
                style={{ backgroundColor: "#F5F4FD" }}
                paddingBottom="5rem"
            >
                <Box
                    padding={isMobile ? "3rem" : "6rem 5rem"}
                    marginTop={!isMobile ? "-12rem" : "0rem"}
                    style={{ backgroundColor: "#FFF" }}
                    paddingBottom="5rem"
                    sx={{
                        borderRadius: "20px"
                    }}
                    display="flex"
                    flexDirection="column"
                >
                    <Box
                        display="flex"
                        flexDirection={isMobile ? "column" : "row"}
                        gap="2rem"
                    >
                        <Box
                            width={isMobile ? "100%" : "60%"}
                            display="flex"
                            paddingRight="3rem"
                            flexDirection="column"
                        >
                            <Typography
                                fontSize="22px"
                                gutterBottom
                                fontFamily="Sora"
                                color="#231558"
                                fontWeight="bold"
                            >
                                Essential Job Functions
                            </Typography>
                            <Typography
                                fontFamily="Sora"
                                fontSize="16px"
                                marginTop="1rem"
                                color="#9795A6"
                            >
                               {vacancy?.description}
                            </Typography>

                            <Typography
                                marginTop="2rem"
                                fontSize="22px"
                                gutterBottom
                                fontFamily="Sora"
                                color="#231558"
                                fontWeight="bold"
                            >
                                Skill and Knowledge Requirements
                            </Typography>

                            <ul>
                                {vacancy?.skills.map((skill) => (
                                    <li>
                                        <Typography
                                            fontFamily="Sora"
                                            fontSize="16px"
                                            color="#9795A6"
                                        >
                                            {skill}
                                        </Typography>
                                    </li>
                                ))}
                            </ul>

                            <Typography
                                marginTop="2rem"
                                fontSize="22px"
                                gutterBottom
                                fontFamily="Sora"
                                color="#231558"
                                fontWeight="bold"
                            >
                                Benefits
                            </Typography>

                            <ul>
                                {vacancy?.benefits.map((benefit) => (
                                    <li>
                                        <Typography
                                            fontFamily="Sora"
                                            fontSize="16px"
                                            color="#9795A6"
                                        >
                                            {benefit}
                                        </Typography>
                                    </li>
                                ))}
                            </ul>
                        </Box>
                        <Box
                            display="flex"
                            width={isMobile ? "80%" : "30%"}
                            flexDirection="column"
                        >
                            <Box
                                width="100%"
                                height="250px"
                                bgcolor="#F2F1F5"
                                borderRadius="12px"
                                padding="2rem"
                                display="flex"
                                flexDirection="column"
                                justifyContent="space-between"
                            >
                                <Box>
                                    <Typography
                                        fontSize="22px"
                                        gutterBottom
                                        fontFamily="Sora"
                                        color="#231558"
                                    >
                                        Need help?
                                    </Typography>
                                    <Typography
                                        fontFamily="Sora"
                                        fontSize="16px"
                                        marginTop="1rem"
                                        color="#9795A6"
                                    >
                                        Talk to our support team.
                                    </Typography>
                                </Box>
                                <Typography
                                    fontSize="18px"
                                    gutterBottom
                                    fontFamily="Sora"
                                    color="#231558"
                                >
                                    +234 909 7533 650
                                </Typography>
                                <Typography
                                    fontSize="18px"
                                    gutterBottom
                                    fontFamily="Sora"
                                    color="#231558"
                                >
                                    info@hafamandco.tech
                                </Typography>
                            </Box>
                            <Box display="flex" marginTop="1.5rem" gap="1rem">
                                <SocialLinks />
                            </Box>
                        </Box>
                    </Box>
                    <Divider />
                    <Box
                        display="flex"
                        flexDirection="column"
                        alignItems="center"
                        justifyContent="center"
                        marginTop="-2.5rem"
                        padding={isMobile ? "0rem" : "0rem 10rem"}
                    >
                        <Typography
                            marginTop="2rem"
                            fontSize="28px"
                            gutterBottom
                            fontFamily="Sora"
                            color="#231558"
                            fontWeight="bold"
                        >
                            Apply for this Job
                        </Typography>

                        <ApplyForm jobID={jobID} isMobile={isMobile} />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
}
