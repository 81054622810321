import React from 'react'
import Block from './block'

export default function Content({ content }) {
    return (
        <div className="blog-details-content">
            <div className="blog-details-rich-text w-richtext">
                {
                    content.blocks.map((item, index) => <Block key={index} item={item} />)
                }
            </div>
        </div>
    )
}
