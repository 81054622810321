import React from "react";
import { Box, Typography, useMediaQuery, Grid, Paper } from "@mui/material";
import LocationCard from '../cards/LocationCard';
import { useLocation } from "../contexts/locations/useLocation";

export default function Locations({ color="light"}) {
    const isMobile = useMediaQuery("(max-width:960px)");
    const { locations } = useLocation()

    const locationss = [
        {
            img:
                "https://templatekit.tokomoo.com/recruitmentkit/wp-content/uploads/sites/115/2022/12/job-location-04.jpg",
            city: "Lagos",
        },
        {
            img:
                "https://templatekit.tokomoo.com/recruitmentkit/wp-content/uploads/sites/115/2022/12/job-location-03.jpg",
            city: "London",
        },
        {
            img:
                "https://templatekit.tokomoo.com/recruitmentkit/wp-content/uploads/sites/115/2022/12/job-location-02.jpg",
            city: "Abuja",
        },
        {
            img:
                "https://templatekit.tokomoo.com/recruitmentkit/wp-content/uploads/sites/115/2022/12/job-location-01.jpg",
            city: "Remote",
        }
    ];
    return (
        <Box marginTop="8rem" textAlign="center">
            <Typography
                variant={isMobile ? "h6" : "h4"}
                gutterBottom
                fontFamily="Sora"
                color= {color === "light" ? "#FFF" : "#000"}
            >
                Select by location
            </Typography>
            <Grid
                container
                spacing={isMobile ? 8 : 4}
                marginTop="2rem"
                direction={isMobile ? "column" : "row"}
            >
                {locations?.map((location, index) =>
                   <LocationCard location={location}  index={index} color={color} />
                )}
            </Grid>
        </Box>
    );
}
