import React from "react";
import { Box, Typography, useMediaQuery, Grid, Paper } from "@mui/material";
import Divider from "../../shared/components/Divider";
import Companies from "../../shared/list/Companies";

export default function About() {
    const isMobile = useMediaQuery("(max-width:960px)");
    const team = [
        {
            img: "https://templatekit.tokomoo.com/recruitmentkit/wp-content/uploads/sites/115/2022/12/search.png",
            role: "CEO & Founder",
            name: "Aminu"
        },
        {
            img: "https://templatekit.tokomoo.com/recruitmentkit/wp-content/uploads/sites/115/2022/12/profile.png",
            role: "COO",
            name: "Clinton"
        },
        {
            img: "https://templatekit.tokomoo.com/recruitmentkit/wp-content/uploads/sites/115/2022/12/profile.png",
            role: "CTO",
            name: "Abubakar Shehu"
        },
        {
            img: "https://templatekit.tokomoo.com/recruitmentkit/wp-content/uploads/sites/115/2022/12/message.png",
            role: "Business Dev Manager",
            name: "Mustapha"
        },
        {
            img: "https://templatekit.tokomoo.com/recruitmentkit/wp-content/uploads/sites/115/2022/12/profile.png",
            role: "Senior Finance Manager",
            name: "Henry"
        },
        {
            img: "https://templatekit.tokomoo.com/recruitmentkit/wp-content/uploads/sites/115/2022/12/profile.png",
            role: "Talent Sourcer",
            name: "Precious"
        }
    ];
    return (
        <Box style={{ backgroundColor: "#F4F9FA" }}>
            <Box
                padding={!isMobile ? "5rem 12rem" : "3rem"}
                style={{ backgroundColor: "#ECF5F7" }}
            >
                <Box
                    style={{ backgroundColor: "#ECF5F7" }}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                >
                    <Box
                        width={isMobile ? "100%" : "50%"}
                        marginBottom="3rem"
                        textAlign="center"
                    >
                        <Typography
                            variant={isMobile ? "h4" : "h2"}
                            fontWeight="bold"
                            gutterBottom
                            fontFamily="Sora"
                            color="#231558"
                        >
                            About Hafam & CO
                        </Typography>
                        <Typography
                            fontSize="16px"
                            fontFamily="Sora"
                            sx={{ color: "#77758C" }}
                        >
                           Empowering Business Success Through Innovation and the Right Talent.
                        </Typography>
                    </Box>
                </Box>
                <Box marginTop="-2.5rem">
                    <Divider />
                </Box>
                <Box
                    marginTop="2rem"
                    display={"flex"}
                    flexDirection={isMobile ? "column" : "row"}
                    gap={"2rem"}
                >
                    <Typography
                        maxWidth={isMobile ? "100%" : "40%"}
                        fontWeight="bold"
                        gutterBottom
                        fontFamily="Sora"
                        color={"#231558"}
                        variant={isMobile ? "h6" : "h4"}
                    >
                        We continue to help people find their passion and job
                    </Typography>
                    <Typography
                        maxWidth={isMobile ? "100%" : "50%"}
                        fontFamily="Sora"
                        sx={{ color: "#77758C" }}
                        marginLeft={"auto"}
                    >
                        Specializing in recruitment, custom software development, and tech consultancy, we strive to provide comprehensive solutions that drive success in the modern marketplace. With a commitment to innovation and the right talent, we aim to be a trusted partner for businesses navigating the evolving landscape of technology and human resources.
                    </Typography>
                </Box>
            </Box>
            <Box
                margin={!isMobile ? "5rem 12rem" : "0rem"}
                padding={isMobile ? "3rem" : "6rem 5rem"}
                marginTop={isMobile ? "0rem" : "5rem"}
                style={{ backgroundColor: "#231558" }}
                paddingBottom="5rem"
                sx={{
                    borderRadius: "20px"
                }}
                display="flex"
                flexDirection={isMobile ? "column" : "row"}
                gap={"2rem"}
            >
                <Box maxWidth={isMobile ? "100%" : "20%"}>
                    <Typography
                        fontSize="26px"
                        gutterBottom
                        fontFamily="Sora"
                        color="#FFF"
                        fontWeight="bold"
                    >
                        About us
                    </Typography>
                    <Typography
                        fontFamily="Sora"
                        fontSize="16px"
                        marginTop="1rem"
                        color="#AFAACB"
                    >
                        Hafam&Co. is a dynamic startup dedicated to empowering businesses in their digital transformation journey. Specializing in recruitment, custom software development, and tech consultancy, we strive to provide comprehensive solutions that drive success in the modern marketplace. With a commitment to innovation and the right talent, we aim to be a trusted partner for businesses navigating the evolving landscape of technology and human resources.

                    </Typography>
                </Box>

                <Box maxWidth={isMobile ? "100%" : "50%"}>
                    <Typography
                        fontSize="30px"
                        gutterBottom
                        fontFamily="Sora"
                        color="#FFF"
                        fontWeight="bold"
                    >
                        Our value
                    </Typography>
                    <Typography
                        fontFamily="Sora"
                        fontSize="16px"
                        marginTop="1rem"
                        color="#AFAACB"
                    >
                        <ul>
                            <li>Innovation: We embrace creativity and forward-thinking to deliver cutting-edge solutions that meet the unique needs of our clients.</li>
                            <li>Integrity: We operate with transparency, honesty, and ethical practices, fostering trust with our clients, partners, and employees.</li>
                            <li>Collaboration: We believe in the power of collaboration, both internally and with our clients, to achieve collective success and continuous improvement.</li>
                            <li>Excellence: We are committed to delivering high-quality services and solutions, setting a standard of excellence in everything we do.</li>
                            <li>Adaptability: In the ever-evolving digital landscape, we remain agile and adaptable, ensuring our clients are equipped to navigate change and stay ahead of the curve.</li>
                        </ul>
                    </Typography>
                </Box>
                <Box maxWidth={isMobile ? "100%" : "20%"}>
                    <Typography
                        fontSize="26px"
                        gutterBottom
                        fontFamily="Sora"
                        color="#FFF"
                        fontWeight="bold"
                    >
                        Our vision
                    </Typography>
                    <Typography
                        fontFamily="Sora"
                        fontSize="16px"
                        marginTop="1rem"
                        color="#AFAACB"
                    >
                       To be a leading force in shaping the future of business by providing innovative solutions and fostering a thriving ecosystem of talent and technology.

                    </Typography>

                    <Typography
                        marginTop={"2rem"}
                        fontSize="26px"
                        gutterBottom
                        fontFamily="Sora"
                        color="#FFF"
                        fontWeight="bold"
                    >
                        Our mission
                    </Typography>
                    <Typography
                        fontFamily="Sora"
                        fontSize="16px"
                        marginTop="1rem"
                        color="#AFAACB"
                    >
                       <ul>
                        <li>Recruitment Excellence: Connect businesses with the best talent, fostering a workforce that drives success and innovation.</li>
                        <li>Bespoke Software Solutions: Design and develop customized software solutions that optimize business operations and enhance efficiency.</li>
                     </ul>
                    </Typography>
                </Box>
            </Box>
            <Box padding={!isMobile ? "5rem 12rem" : "3rem"}>
                {/* <Typography
                    fontSize="20px"
                    fontFamily="Sora"
                    sx={{ marginBottom: "2rem" }}
                >
                    Trusted by companies worldwide{" "}
                </Typography>
                <Companies /> */}
                <Divider />
                <Box>
                    <Typography
                        variant={isMobile ? "h4" : "h2"}
                        gutterBottom
                        fontFamily="Sora"
                        color="#28195C"
                    >
                        Meet our team
                    </Typography>
                    <Box display="flex" flexDirection={isMobile ? "column" : "row"}>
                        <Typography
                            fontFamily="Sora"
                            fontSize="16px"
                            color="#7A788E"
                            style={{
                                flexGrow: 1
                            }}
                        >
                            At Hafam & Co., our team is the core of our innovation and success. Comprised of industry experts, creative thinkers, and tech enthusiasts, each member brings a unique blend of passion and expertise to the table. Get to know the faces behind our solutions—dedicated professionals committed to advancing your business in the digital world.
                        </Typography>
                        {/* <Typography
                            marginTop={isMobile ? "1.5rem" : "-1rem"}
                            fontFamily="Sora"
                            fontSize="20px"
                            color="#28195C"
                            sx={{
                                "&:hover": {
                                    color: "#7D5DF4",
                                    cursor: "pointer",
                                }
                            }}
                        >
                            View All Team
                        </Typography> */}
                    </Box>

                    <Grid
                        container
                        spacing={isMobile ? 8 : 4}
                        marginTop="2rem"
                        direction={isMobile ? "column" : "row"}
                        alignItems={"center"}
                        justifyContent={"center"}
                    >
                        {team.map((worker, index) => (
                            <Grid
                                item
                                xs={12}
                                sm={6}
                                md={3}
                                key={index}
                                style={{
                                    display: "flex",
                                    flexDirection: "column",
                                    justifyContent: "center"
                                }}
                            >
                                <Paper
                                    display="flex"
                                    alignItems="cenetr"
                                    justifyContent="center"
                                    textAlign="ceneter"
                                    sx={{
                                        display: "flex",
                                        textAlign: "center",
                                        padding: "2rem 1.5rem",
                                        backgroundColor: "transparent",
                                        display: "flex",
                                        flexDirection: "column",
                                        height: "100%",
                                        backgroundColor: "#FFF",
                                        borderRadius: "12px"
                                    }}
                                >
                                    <img
                                        src={worker.img}
                                        alt={worker.name}
                                        style={{
                                            alignSelf: "center",
                                            width: "150px",
                                            height: "auto",
                                            borderRadius: "12px",
                                            marginBottom: "0.5rem"
                                        }}
                                    />
                                    <Typography
                                        fontSize="18px"
                                        fontWeight="bold"
                                        gutterBottom
                                        fontFamily="Sora"
                                        style={{
                                            marginTop: "1.5rem",
                                            marginBottom: "0.5rem"
                                        }}
                                        color="#000"
                                    >
                                        {worker.name}
                                    </Typography>
                                    <Typography
                                        fontFamily="Sora"
                                        fontSize="16px"
                                        color="#7A788E"
                                        style={{
                                            flexGrow: 1
                                        }}
                                    >
                                        {worker.role}
                                    </Typography>
                                </Paper>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
                <Box marginTop="10rem">
                    <Divider />
                </Box>
            </Box>
        </Box>
    );
}
