import React from "react";
import { Box, Typography, Grid, Paper } from "@mui/material";
import { useNavigate } from 'react-router-dom';

export default function VacancyCard({ vacancy, index, color }) {
    const navigate = useNavigate()
    return (
        <Grid
            item
            xs={12}
            sm={6}
            md={3}
            key={index}
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
            }}
        >
            <Paper
                onClick={() => {
                    navigate(`/jobs/${vacancy.id}`)
                    window.scrollTo({
                        top: 0,
                        left: 0,
                        behavior: "smooth",
                      });
                }}
                sx={{
                    padding: "2rem 1.5rem",
                    backgroundColor: "transparent",
                    display: "flex",
                    flexDirection: "column",
                    height: "100%",
                    backgroundColor: color === 'dark' ? "#302262" : "#FFF",
                    borderRadius: "12px",
                    "&:hover": {
                        border: "1px solid",
                        borderColor: "#7D5DF4",
                        padding: "2rem 1.4rem",
                        cursor: "pointer",
                        transition: "box-shadow 0.3s ease-in-out"
                    }
                }}
            >
                <img
                    src={vacancy.img}
                    alt={vacancy.title}
                    style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "12px",
                        marginBottom: "0.5rem"
                    }}
                />
                <Typography
                    fontSize="18px"
                    fontWeight="bold"
                    gutterBottom
                    fontFamily="Sora"
                    style={{
                        marginTop: "1.5rem",
                        marginBottom: "0.5rem"
                    }}
                    color= {color === 'dark' ? "#FFF" : "#000"}
                >
                    {vacancy.title}
                </Typography>
                <Typography
                    fontFamily="Sora"
                    fontSize="16px"
                    color="#7A788E"
                    style={{
                        flexGrow: 1
                    }}
                >
                    {vacancy.date}
                </Typography>
                <Box
                    sx={{
                        marginTop: "12px"
                    }}
                >
                    <Typography
                        fontFamily="Sora"
                        fontWeight="bold"
                        fontSize="16px"
                        color= {color === 'dark' ? "#FFF" : "#000"}
                        style={{
                            flexGrow: 1
                        }}
                        sx={{
                            "&:hover": {
                                color: "#7D5DF4",
                                cursor: "pointer"
                            }
                        }}
                    >
                        Read More {">>"}
                    </Typography>
                </Box>
            </Paper>
        </Grid>
    );
}
