
import React from 'react'
import {
    Box,
    Typography,
} from '@mui/material'
import { useNavigate } from 'react-router-dom';


export default function CardButton({ text, onPress, color, textColor }) {
    const navigate = useNavigate()
    return (
        <div style={{
            display: "flex",
            marginTop: "4rem"
        }}>
            <Box
                className="card--button"
                bgcolor={color ? color : "#7D5DF4"}
                padding={"0rem 2.5rem"}
                onClick={onPress}
            >
                <Typography fontSize="16px" fontFamily="Sora" whiteSpace="nowrap" color={textColor || "#fff"}>
                    {text}
                </Typography>
            </Box>
        </div>
    );
}