import React, { useState } from 'react'
import { collection, query, onSnapshot } from "firebase/firestore";
import { db } from '../../../config';

export const useBlog = () => {
    const [articles, setArticles] = useState([])

    // const articles = [
    //     {
    //         img:
    //             "https://templatekit.tokomoo.com/recruitmentkit/wp-content/uploads/sites/115/2023/01/blog-9.jpg",
    //         title: "Why AI-Powered Recruitment Can Be Problematic",
    //         date: "January 9, 2023 4:40 am"
    //     },
    //     {
    //         img:
    //             "https://templatekit.tokomoo.com/recruitmentkit/wp-content/uploads/sites/115/2023/01/blog-8.jpg",
    //         title: "How Recruiters Can Support First-Gen College Students",
    //         date: "January 9, 2023 4:40 am"
    //     },
    //     {
    //         img:
    //             "https://templatekit.tokomoo.com/recruitmentkit/wp-content/uploads/sites/115/2023/01/blog-7.jpg",
    //         title: "How Early Talent Teams Can Drive Change in an Organization",
    //         date: "January 9, 2023 4:40 am"
    //     },
    //     {
    //         img:
    //             "https://templatekit.tokomoo.com/recruitmentkit/wp-content/uploads/sites/115/2023/01/blog-4.jpg",
    //         title: "Creating a Work Culture to Appeal to Gen Z",
    //         date: "January 9, 2023 4:40 am"
    //     }
    // ];

    React.useEffect(() => {
        const fetchData = async () => { 
            const q = query(collection(db, "articles"));
            onSnapshot(q, (querySnapshot) => {
                const articles = [];
                querySnapshot.forEach((doc) => {
                    articles.push({id: doc.id, ...doc.data()});
                });
                setArticles(articles)
            });
        }
        fetchData()
    }, [])

    return { articles }
}
