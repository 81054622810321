import React from "react";
import { Box } from "@mui/material";

export default function Divider({ color="light"}) {
    return (
        <Box
            margin="6rem 0rem"
            sx={{
                borderTop: "1.5px solid",
                borderColor: color === "light" ? "#DFE2E8" : "#000"
            }}
        />
    );
}
