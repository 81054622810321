import React from 'react'
import { Typography } from '@mui/material'

export default function Quote({ data }) {
    return (
        <Typography
      marginTop={"2rem"}
      fontFamily="Sora"
      fontSize="16px"
      fontWeight={"700"}
    >
     "{data.text}"
    </Typography>
    )
}
