import React from 'react'
import { Typography } from '@mui/material'

export default function Paragraph({ data }) {
  return (
    <Typography
      marginTop={"2rem"}
      fontFamily="Sora"
      fontSize="14px"
    >
      {data.text}
    </Typography>
  )
}
