import React from "react";
import { useMediaQuery, Grid, } from "@mui/material";
import VacancyCard from "../cards/VacancyCard";
import { useVacancies } from "../contexts/vacancy/useVacancies";

export default function Vacancies({ maxNum, color='dark'}) {
    const isMobile = useMediaQuery("(max-width:960px)");

    const { vacancies } = useVacancies()

    return (
        <Grid
            container
            spacing={isMobile ? 8 : 4}
            marginTop="2rem"
            direction={isMobile ? "column" : "row"}
        >
            {maxNum
                ? vacancies
                    .slice(0, maxNum)
                    .map((vacancy, index) =>
                        <VacancyCard vacancy={vacancy} index={index} color={color}/>
                    )
                : vacancies.map((vacancy, index) =>
                    <VacancyCard vacancy={vacancy} index={index} color={color}/>
                )}
        </Grid>
    );
}
