import React from "react";
import { Box, Typography, useMediaQuery, Grid } from "@mui/material";

export default function HowSection() {
    const isMobile = useMediaQuery("(max-width:960px)");

    const steps = [
        { img: 'https://templatekit.tokomoo.com/recruitmentkit/wp-content/uploads/sites/115/2022/12/search.png', title: 'Explore your options', description: 'At Hafam & Co., we believe in creating paths to success for businesses and professionals alike. Our bespoke software solutions are designed to streamline your operations and enhance efficiency.' },
        { img: 'https://templatekit.tokomoo.com/recruitmentkit/wp-content/uploads/sites/115/2022/12/message.png', title: 'Talk on your terms', description: 'In the digital age, having the right tech advice is crucial. Our expert consultants at Hafam & Co. are here to provide you with strategic guidance on your terms.' },
        { img: 'https://templatekit.tokomoo.com/recruitmentkit/wp-content/uploads/sites/115/2022/12/profile.png', title: 'Get hired', description: "Connecting top-tier talent with pioneering companies is our specialty. With Hafam & Co.'s recruitment services, candidates find their ideal roles and businesses find the innovators who will drive their success." },
    ];
    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            padding={!isMobile ? "5rem 12rem" : "3rem"}
        >
            <Grid container spacing={isMobile ? 1 : 3} marginTop="2rem" direction={isMobile ? "column" : "row"}>
                {steps.map((step, index) => (
                    <Grid item xs={8} sm={3} md={4} key={index}
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                        }}
                    >
                        <Box
                            sx={{
                                padding: '2rem 1.5rem',
                                textAlign: 'center',
                                backgroundColor: 'transparent',
                                display: 'flex',
                                flexDirection: 'column',
                                height: '100%',
                                backgroundColor: '#FFF',
                                borderRadius: '12px',
                                "&:hover": {
                                    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -4px rgba(0, 0, 0, 0.06), 0 -2px 4px -1px rgba(0, 0, 0, 0.06)',
                                    transition: 'box-shadow 0.3s ease-in-out',
                                }
                            }}
                        >
                            <img
                                src={step.img}
                                alt={step.title}
                                style={{
                                    alignSelf: 'center',
                                    width: '100px',
                                    height: '100px',
                                    borderRadius: '50%',
                                    marginBottom: '0.5rem'
                                }}
                            />
                            <Typography
                                fontSize="18px"
                                fontWeight="bold"
                                gutterBottom
                                fontFamily="Sora"
                                style={{
                                    marginTop: '1.5rem',
                                    marginBottom: '0.5rem'
                                }}
                            >
                                {step.title}
                            </Typography>
                            <Typography
                                fontFamily="Sora"
                                fontSize="16px"
                                color="#7A788E"
                                style={{
                                    flexGrow: 1
                                }}
                            >
                                {step.description}
                            </Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )

}
