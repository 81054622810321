import React, { useState } from 'react'
import { collection, query, where, onSnapshot, addDoc, deleteDoc, doc } from "firebase/firestore";
import { db } from '../../../config';
import {
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
    deleteObject
} from "@firebase/storage";

export const useLocation = () => {
    const [locations, setLocations] = useState([])

    React.useEffect(() => {
        const fetchData = async () => { 
            const q = query(collection(db, "locations"));
            onSnapshot(q, (querySnapshot) => {
                const locations = [];
                querySnapshot.forEach((doc) => {
                    locations.push({id: doc.id, ...doc.data()});
                });
                
                setLocations(locations)
            });
            
        }
        fetchData()
    }, [])


    const addLocation = async (city, image) => {
        const storage = getStorage();

        const imageRef = ref(storage, `Locations/${image.name}`);
        const uploadTask = uploadBytesResumable(imageRef, image);

        await uploadTask;

        const imageUrl = await getDownloadURL(imageRef);

        await addDoc(collection(db, "locations"), {
            city: city,
            img: imageUrl,
        });
    };

    const deleteLocation = async (id) => {
        await deleteDoc(doc(db, "locations", id));
    }

    return { locations, addLocation, deleteLocation }
}
