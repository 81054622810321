import React from "react";
import { Box, useMediaQuery, Typography } from "@mui/material";

export default function NewsLetter() {
    const isMobile = useMediaQuery("(max-width:960px)");
    const [email, setEmail] = React.useState("");

    const handleChange = (e) => {
        setEmail(e.target.value);
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        alert(`Subscribed with: ${email}`);
        // Your subscription logic here
    };
    return (
        <Box
            padding={!isMobile ? "5rem 12rem" : "1rem"}
            paddingBottom="5rem"
            display="flex"
            textAlign="center"
            alignItems="center"
            justifyContent="center"
        >
            <Box
                alignSelf="center"
                display="flex"
                textAlign="center"
                alignItems="center"
                justifyContent="center"
                width={isMobile ? "100%" : "60%"}
                padding={"2rem"}
                sx={{
                    borderRadius: "20px",
                    backgroundColor: "#7860F5"
                }}>
                <Box>
                    <div className="hero">
                        <div className="page--hero">
                            <p
                                className="hero--title"
                                style={{ fontSize: "36px", fontWeight: "500", color: "#FFF", fontFamily: "Sora" }}
                            >
                                Find your next{" "}
                            </p>
                            <p
                                className="hero--title"
                                style={{ fontSize: "36px", fontWeight: "500", color: "#FFF", fontFamily: "Sora" }}
                            >
                                great opportunity!
                            </p>
                        </div>
                    </div>
                    <form onSubmit={handleSubmit} className="subscribe-form">
                        <input
                            type="email"
                            value={email}
                            onChange={handleChange}
                            placeholder="name@email.com"
                            className="subscribe-input"
                            required
                        />
                        <button type="submit" className="subscribe-button">
                            Subscribe
                        </button>
                    </form>
                    <Typography
                        marginTop="2rem"
                        fontFamily="Sora"
                        fontSize="16px"
                        color="#FFF"
                        style={{
                            flexGrow: 1
                        }}
                    >
                        Join 24,000+ users already on the newsletter!
                    </Typography>

                </Box>
            </Box>
        </Box>
    );
}
