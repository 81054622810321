import React, { useEffect, useState } from 'react'
import { collection, query, where, onSnapshot, addDoc, deleteDoc, doc } from "firebase/firestore";
import { db } from '../../../config';
import {
    getStorage,
    ref,
    uploadBytesResumable,
    getDownloadURL,
    deleteObject
} from "@firebase/storage";

export const useVacancies = () => {
    const [vacancies, setVacancies] = useState([])

    const date = new Date(); // You can also use a specific date by passing it to the constructor

    const formattedDate = new Intl.DateTimeFormat('en-US', {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
        hour: 'numeric',
        minute: '2-digit',
        hour12: true // Use 12-hour format
    }).format(date);

    useEffect(() => {
        const fetchData = async () => {
            const q = query(collection(db, "vacancies"));
            onSnapshot(q, (querySnapshot) => {
                const vacancies = [];
                querySnapshot.forEach((doc) => {
                    vacancies.push({ id: doc.id, ...doc.data() });
                });
                setVacancies(vacancies)
            });
        }

        fetchData()

    }, [])


    const addVacancy = async (title, description, skills, benefits, location, showSalary, salary, image, type) => {
        const storage = getStorage();

        const imageRef = ref(storage, `VacancyImages/${image.name}`);
        const uploadTask = uploadBytesResumable(imageRef, image);

        await uploadTask;

        const imageUrl = await getDownloadURL(imageRef);

        await addDoc(collection(db, "vacancies"), {
            title: title,
            description: description,
            skills: skills,
            benefits: benefits,
            location: location,
            showSalary: showSalary,
            salary: salary,
            img: imageUrl,
            date: formattedDate,
            type: type
        });
    };

    const applyJob = async (firstName, lastName, phoneNumber, email, info, resume, jobId) => {
        const storage = getStorage();

        const fileRef = ref(storage, `Resumes/${resume.name}`);
        const uploadTask = uploadBytesResumable(fileRef, resume);

        await uploadTask;

        const resumeUrl = await getDownloadURL(fileRef);

        await addDoc(collection(db, "applications"), {
            firstName: firstName,
            lastName: lastName,
            email: email,
            phoneNumber: phoneNumber,
            info: info,
            jobId: jobId,
            resume: resumeUrl
        });
    };


    const deleteVacancy = async (id) => {
        await deleteDoc(doc(db, "vacancies", id));
    }

    return { vacancies, addVacancy, deleteVacancy, applyJob }
}
