import React from "react";
import { Box, Typography, useMediaQuery, Grid, Paper } from "@mui/material";

export default function LocationCard({ location, index, color }) {
    return (
        <Grid
            item
            xs={12}
            sm={6}
            md={3}
            key={index}
            style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center"
            }}
            sx={{
                "&:hover": {
                    transform: "scale(1.1)",
                    cursor: "pointer",
                    transition: "transform 0.3s ease-in-out"
                }
            }}
        >
            <Paper
                elevation={0}
                sx={{
                    backgroundColor: "transparent",
                    display: "flex",
                    flexDirection: "column",
                    height: "200px",
                    backgroundColor: "#302262",
                    borderRadius: "12px"
                }}
            >
                <img
                    src={location.img}
                    alt={location.city}
                    style={{
                        maxWidth: "100%",
                        height: "100%",
                        borderRadius: "12px",
                    }}
                    
                />
            </Paper>
            <Typography
                fontSize="18px"
                fontWeight="bold"
                gutterBottom
                fontFamily="Sora"
                style={{
                    marginTop: "1.5rem",
                    marginBottom: "0.5rem"
                }}
                color={color === "light" ? "#FFF" : "#000"}
            >
                {location.city}
            </Typography>
        </Grid>
    );
}
