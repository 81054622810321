import React from 'react'

export default function Image({data}) {
  return (
   
    <img
      src={data.file.url}
      alt={data.file.url}
      style={{
         marginTop: '1.5rem',
          width: "100%",
          height: "400px",
          borderRadius: "12px",
          objectFit: 'contain'

      }}
      objectFit="contain"
  />
  )
}
