import React from 'react'
import HeroSection from './Sections/HeroSection';
import HowSection from './Sections/HowSection';
import Featured from './Sections/Featured';
import BlogsSection from './Sections/BlogsSection';
import NewsLetter from '../../shared/components/NewsLetter';

export default function Home() {
    return (
        <div data-elementor-type="wp-page" data-elementor-id="1306" class="elementor elementor-1306" data-elementor-post-type="page">
           <HeroSection />
           <HowSection />
           <Featured />
           <BlogsSection />
           <NewsLetter />
        </div>
    )
}
