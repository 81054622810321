import React from "react";
import {
    Box,
    Typography,
    TextField,
    Button
} from "@mui/material";

export default function ContactForm({ isMobile }) {
    return (
        <form
            style={{
                width: "100%"
            }}
        >
            <Box
                display="flex"
                gap={isMobile ? "0rem" : "1.5rem"}
                flexDirection={isMobile ? "column" : "row"}
            >
                <TextField
                    fullWidth
                    required
                    label="Full Name"
                    type="text"
                    margin="normal"
                />
                <TextField
                    fullWidth
                    required
                    label="Email Address"
                    type="email"
                    margin="normal"
                />
            </Box>
            <Box
                display="flex"
                gap={isMobile ? "0rem" : "1.5rem"}
                flexDirection={isMobile ? "column" : "row"}
            >
                <TextField
                    fullWidth
                    required
                    label="Phone Number"
                    placeholder="(+123) 4567-890"
                    type="text"
                    margin="normal"
                />
                <TextField
                    fullWidth
                    required
                    label="Subject"
                    type="text"
                    margin="normal"
                />
            </Box>

            <TextField
                required
                fullWidth
                label="Tell us how we can help you"
                type="text"
                margin="normal"
                placeholder="Add any additional details"
                multiline
                rows={6}
            />
            <Button
                type="submit"
                className="card--button"
                style={{
                    backgroundColor: "#7D5DF4",
                    padding: "5px 22px",
                    borderRadius: "12px",
                    marginTop: "1.5rem",
                    textTransform: "none"
                }}
            >
                <Typography
                    fontSize="20px"
                    fontFamily="Sora"
                    whiteSpace="nowrap"
                    color="#fff"
                >
                    Submit
                </Typography>
            </Button>
        </form>
    );
}
