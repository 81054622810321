import { BrowserRouter, Routes, Route } from "react-router-dom";
import AppLayout from "./Layout/main/main";
import Home from "./scenes/Home";
import Jobs from "./scenes/Jobs";
import JobInfo from "./scenes/Jobs/JobInfo";
import About from "./scenes/About";
import Contact from "./scenes/Contact";
import Blog from "./scenes/Blog";
import Reader from "./scenes/Blog/reader/Reader";

import MaintenancePage from "./Miantenance";

function App() {
  return (
    <div className="App">
      {/* <MaintenancePage /> */}
      <BrowserRouter>
        <Routes>
          <Route element={<AppLayout />}>
            <Route path="/" element={<Home />} />
            <Route path="jobs" element={<Jobs />} />
            <Route path="jobs/:jobID" element={<JobInfo />} />
            <Route path="about" element={<About />} />
            <Route path="contact" element={<Contact />} />
            <Route path="blog" element={<Blog />} />
            <Route path="blog/:articleId" element={<Reader />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;
