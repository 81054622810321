import React from 'react'
import { Box, Typography, useMediaQuery, Grid, Paper } from "@mui/material";
import { Blogs } from '../../../shared/list/Blogs';
import { useNavigate } from 'react-router-dom';

export default function BlogsSection() {
    const isMobile = useMediaQuery("(max-width:960px)");
    const navigate = useNavigate()
    
    return (
        <Box
            padding={!isMobile ? "5rem 12rem" : "3rem"}
            paddingBottom="5rem"
        >
            <Box display="flex" justifyContent="space-between" flexDirection={isMobile ? "column" : "row"}>
                <Typography
                    variant={isMobile ? "h6" : "h4"}
                    gutterBottom
                    fontFamily="Sora"
                >
                   Explore our recruiting blog
                </Typography>
                <Typography
                     onClick={() => {
                        navigate("blog")
                        window.scrollTo({
                            top: 0,
                            left: 0,
                            behavior: "smooth",
                          });
                       
                    }}
                    fontFamily="Sora"
                    fontSize="20px"
                    sx={{
                        "&:hover": {
                            color: "#7D5DF4",
                            cursor: "pointer",
                        }
                    }}
                >
                    View All Blogs
                </Typography>
            </Box>
            {/* <Blogs /> */}
        </Box>
    )
}
