import React from "react";
import { Box, Typography, useMediaQuery, Button } from "@mui/material";
import CardButton from "../../../shared/components/CardButton";
import Companies from "../../../shared/list/Companies";
import Divider from "./../../../shared/components/Divider";
import { useNavigate } from "react-router-dom";
import hero from "../../../assets/images/Container.png"

const HeroSection = () => {
  const isMobile = useMediaQuery("(max-width:960px)");
  const navigate = useNavigate();

  return (
    <Box
      padding={!isMobile ? "5rem 12rem" : "3rem"}
      style={{ backgroundColor: "#ECF5F7" }}
    >
      <Box
        display="flex"
        flexDirection={isMobile ? "column" : "row"}
        justifyContent="space-between"
        alignItems="center"
      >
        <Box
          maxWidth={isMobile ? "100%" : "50%"}
          mb={isMobile ? "2rem" : "0"}
          marginTop={isMobile ? "2rem" : "0"}
        >
          <Typography
            variant={isMobile ? "h4" : "h2"}
            fontWeight="bold"
            gutterBottom
            fontFamily="Sora"
            color={"#231558"}
          >
            Discover more than 4500+ jobs
          </Typography>
          <Typography
            class="hero--description_text"
            fontFamily="Sora"
            sx={{ color: "#77758C" }}
          >
            Empowering Business Success Through Innovation and the Right Talent.
          </Typography>
          <Box display={'flex'} flexDirection={isMobile ? "column" : "row"}>
            <CardButton
              text="Search Jobs"
              onPress={() => {
                navigate("jobs");
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth"
                });
              }}
            />
            <CardButton
              text="Join as a Company"
              textColor={"#000"}
              color={'#fff'}
              onPress={() => {
                navigate("contact");
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth"
                });
              }}
            />
          </Box>

        </Box>
        <Box
          maxWidth={isMobile ? "100%" : "50%"}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <img
            src={hero}
            alt="Hero"
            style={{ width: "100%", borderRadius: isMobile ? "0" : "10px" }}
          />
        </Box>
      </Box>
      <Box margin="3rem 0rem">
        <Divider />
        <Typography
          fontSize="20px"
          fontFamily="Sora"
          sx={{ marginBottom: "2rem" }}
        >
          Trusted by companies worldwide{" "}
        </Typography>
        <Companies />
      </Box>
    </Box>
  );
};

export default HeroSection;
