import React from 'react'
import CircleButton from './CircleButton';
import { Twitter, LinkedIn, Instagram, Facebook } from "@mui/icons-material";

export default function SocialLinks() {
    const socialButtons = [
        { id: 1, platform: "LinkedIn", Icon: LinkedIn, link: "https://www.linkedin.com/company/hafam-and-co/"},
        { id: 2, platform: "Twitter", Icon: Twitter, link: "https://x.com/hafamandco?s=21"},
        { id: 3, platform: "Instagram", Icon: Instagram, link: "https://instagram.com/hafam_and_co?igshid=MTk0NTkyODZkYg=="},
    ];
    return (
        socialButtons.map((social) => (
            <CircleButton
                key={social.id}
                size="medium"
                color="#D4D4D4"
                onClick={() => window.open(social.link, '_blank')}
                child={
                    <social.Icon sx={{ color: "#291B5D", fontSize: 22 }} />
                }
            />
        ))
    )
}
