import {
    useMediaQuery,
    IconButton,
    Box
} from "@mui/material";
import { Menu, Close } from "@mui/icons-material";
import React from 'react'
import FlexBetween from '../../shared/components/FlexBetween'
import logo from "../../logo.jpeg"
import NavLinks from "./components/NavLinks";
import { useNavigate } from "react-router-dom";

export default function NavBar() {
    const isNonMobileScreens = useMediaQuery("(min-width: 1022px)");
    const [isMenuOpen, setIsMenuOpen] = React.useState(false)
    const navigate= useNavigate()

    function toggleMenu() {
        if(isMenuOpen) {
            setIsMenuOpen(prevState => !prevState)
        } 
    }
    return (
        <FlexBetween
            padding={isNonMobileScreens ? "1.5rem 12rem" : "0.5rem"}
        >
            <FlexBetween onClick={() => navigate("/")} gap="1.5rem">
                <img src={logo} alt="Logo" style={{ height: '3rem' }} />
            </FlexBetween>
            {
                isNonMobileScreens ? (
                    <FlexBetween gap="2.5rem">
                        <NavLinks />
                    </FlexBetween>
                ) : (
                    <IconButton
                    onClick={() => setIsMenuOpen(!isMenuOpen)}
                        style={{ marginLeft: "1rem" }}
                    >
                        <Menu color="primary" />
                    </IconButton>
                )
            }
            {
                isMenuOpen && !isNonMobileScreens && (
                    <Box
                        position="fixed"
                        right="0"
                        top="0"
                        zIndex="10"
                        maxWidth="500px"
                        minWidth="300px"
                        paddingBottom="30px"
                        backgroundColor="#FFF"
                        sx={{
                            borderBottomLeftRadius: "12px",
                            borderBottomRightRadius: "12px"
                        }}
                    >
                        <Box display="flex" justifyContent="flex-end" p="1rem">
                            <IconButton
                                onClick={() => setIsMenuOpen(!isMenuOpen)}
                            >
                                <Close color="#7D5DF4"/>
                            </IconButton>
                        </Box>
                        <FlexBetween
                            display="flex"
                            flexDirection="column"
                            justifyContent="center"
                            alignItems="center"
                            gap="3rem"
                        >
                            <NavLinks toggleMenu={toggleMenu}/>
                        </FlexBetween>
                    </Box>
                )
            }
        </FlexBetween>
    )
}
