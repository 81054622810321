import React, { useState } from "react";
import NavItem from './NavItem';
import { useNavigate, useLocation } from 'react-router-dom';

export default function NavLinks(props) {
    const navigate = useNavigate()
    const location = useLocation()
    const currentPath = location.pathname;

    const [activeTab, setActiveTab] = useState(currentPath)

    const handleOnPress = (item) => {
        if(props.toggleMenu){
            props.toggleMenu()
        }
        navigate(item.link)
        setActiveTab(item.title)
    }

    const navLinks = [ 
        { title: "Home", link: "/" }, 
        { title: "About", link: "about" },
        { title: "Jobs", link: "jobs" }, 
        // { title: "Clients", link: "jobs" }, 
        { title: "Articles", link: "blog" }, 
        { title: "Contact", link: "contact" },
    ]
    return (
        navLinks.map((item) => (
            <NavItem item={item} activeTab={activeTab} onClick={() => handleOnPress(item)} currentPath={currentPath}/>
        ))
    );
}
