// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";


// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyAXAL30NCcSUc6GglhiyCXTizzJia_-FAg",
  authDomain: "hafamandco.firebaseapp.com",
  projectId: "hafamandco",
  storageBucket: "hafamandco.appspot.com",
  messagingSenderId: "941394927668",
  appId: "1:941394927668:web:3e6114820d18cc7d1c2a61"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
const db = getFirestore(app);

export { db, auth}