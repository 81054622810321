import React from "react";
import icons from "../../assets/constants/icons";
import CompanyCard from "../cards/CompanyCard";

export default function Companies() {
    const companies = [
        { id: 1, name: "Bybit", icon: icons.bybit },
        { id: 2, name: "Next Basket", icon: icons.next },
        { id: 3, name: "SoftSavvy", icon: icons.softsavvy },
        { id: 5, name: "KuCoin", icon: icons.kucoin },
        { id: 6, name: "Neom", icon: icons.neom},
    ];

    return (
        <div className="skills-container">
            <div className="skill--card-row skill--card-row-1">
                {companies.concat(companies).map((company, index) => (
                    <CompanyCard
                        key={index} // Make sure to add a key for each item in the list
                        company={company}
                    />
                ))}
            </div>
        </div>
    )
}
