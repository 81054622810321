import NavBar from "../../scenes/navbar";
import Footer from "../../scenes/footer";
import { Outlet } from "react-router-dom";

export default function AppLayout() {
  return (
    <>
      <NavBar />
      <Outlet />
      <Footer />
    </>
  );
}
