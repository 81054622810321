import React from 'react'
import Image from './content-types/image'
import Quote from './content-types/quote'
import List from './content-types/list'
import Paragraph from './content-types/paragraph'

export default function Block({item}) {
    if(item.type === 'image') return <Image data={item.data} />
    if(item.type === 'quote') return <Quote  data={item.data} />
    if(item.type === 'list') return <List data={item.data} />
    if(item.type === 'paragraph') return <Paragraph data={item.data} />
  return (
    <div>
      
    </div>
  )
}
