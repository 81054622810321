import React from "react";
import { Box, Typography, useMediaQuery, Button } from "@mui/material";
import Vacancies from "../../shared/list/Vacancies";
import Locations from "../../shared/list/Locations";
import NewsLetter from "../../shared/components/NewsLetter";

export default function Jobs() {
    const isMobile = useMediaQuery("(max-width:960px)");
    return (
        <Box padding={!isMobile ? "5rem 12rem" : "3rem"} style={{ backgroundColor: "#ECF5F7" }} >
            <Box
                style={{ backgroundColor: "#ECF5F7" }}
                display="flex"
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
            >
                <Box
                    width={isMobile ? "100%" : "50%"}
                    marginBottom="3rem"
                    textAlign="center"
                >
                    <Typography
                        variant={isMobile ? "h4" : "h2"}
                        fontWeight="bold"
                        gutterBottom
                        fontFamily="Sora"
                        color="#231558"
                    >
                        Find your dream job
                    </Typography>
                    <Typography
                        fontSize="16px"
                        fontFamily="Sora"
                        sx={{ color: "#77758C" }}
                    >
                        Embark on a career journey that fulfills your aspirations at Hafam & Co. With us, you'll discover opportunities that not only match your skills but also ignite your passion for innovation and growth. Let your talents shine in an environment that celebrates creativity, encourages curiosity, and drives progress. Your dream job awaits—where your professional goals meet our vision for a digital future.
                    </Typography>
                </Box>
            <Vacancies color="light" />
            </Box>
            <Locations color="dark" />
            <NewsLetter />
        </Box>
    );
}
