import React, { useState } from "react";
import { Box, Typography, useMediaQuery } from "@mui/material";
import { useParams } from "react-router-dom";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../config";
import Content from "./ui/content";

export default function Reader() {
    const isMobile = useMediaQuery("(max-width:960px)");
    const [article, setArticle] = useState()

    const { articleId } = useParams();

    React.useEffect(() => {
        const unsub = onSnapshot(doc(db, "articles", articleId), (doc) => {
            const articleData = { id: doc.id, ...doc.data() };
            console.log(articleData)
            setArticle(articleData);
        });

        return () => unsub();
    }, [articleId]);

    if(!article) return null
    // console.log(article?.article.content)

    return (
        <Box
            padding={!isMobile ? "5rem 12rem" : "3rem 1rem"}
            style={{ backgroundColor: "#ECF5F7" }}
        >
            <Box padding={!isMobile ? "0rem 15rem" : "0rem"}>
                <img
                    src={article?.article.banner}
                    alt={article?.article.title}
                    style={{
                        width: "100%",
                        height: "600px",
                        borderRadius: "12px",
                        objectFit: 'cover'

                    }}
                    objectFit="contain"
                />
                <Typography
                    fontSize="26px"
                    fontWeight="bold"
                    gutterBottom
                    fontFamily="Sora"
                    style={{
                        marginTop: "1.5rem",
                        marginBottom: "0.5rem"
                    }}
                >
                    {article?.article.title}
                </Typography>
                <Typography
                    fontFamily="Sora"
                    fontSize="16px"
                    color="#7A788E"
                    style={{
                        flexGrow: 1
                    }}
                >
                    {article?.article.date} {"|"} By {article?.article.author}
                </Typography>
                <Content content={article?.article.content} />
            </Box>
        </Box>
    )
}
